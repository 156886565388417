<template>
  <div
    v-loading="loading"
    class="book-create"
  >
    <div class="book-create-wrapper">
      <div
        v-for="field in activeFields"
        :key="field.id"
        class="book-create__row"
      >
        <r-text color-type="subhead">
          {{ field.name }}
        </r-text>
        <el-date-picker
          v-if="field.datatype === 'datetime' || field.type === 'datetime'"
          v-model="field.value"
          class="r-date-picker"
          type="date"
          name="date"
          format="dd.MM.yyyy HH:mm"
          :clearable="false"
          :disabled="isDisabled(field.title, field)"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
        <el-date-picker
          v-if="field.datatype === 'date' || field.type === 'date'"
          v-model="field.value"
          class="r-date-picker"
          type="date"
          name="date"
          format="dd.MM.yyyy"
          :clearable="false"
          :disabled="isDisabled(field.title, field)"
          :picker-options="{ firstDayOfWeek: 1 }"
        />
        <el-input
          v-if="field.type === 'input'"
          v-model="field.value"
          class="r-input"
          placeholder="Введите текст"
        />
        <el-switch
          v-if="field.type === 'boolean'"
          v-model="field.value"
          class="r-switch"
        />
        <el-select
          v-else-if="field.type === 'select' || field.type === 'multi-select'"
          v-model="field.value"
          class="r-select"
          placeholder="Выбрать"
          filterable
          :loading="selectLoading"
          :multiple="field.type === 'multi-select'"
          clearable
          @visible-change="getDropDownData($event, field)"
        >
          <el-option
            v-for="item in getReflectionOptionsRead(field)"
            :key="item.id"
            :label="getReflectionLabelAttr(field, item)"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div class="book-create__control">
      <r-button
        simple
        @click="cancel"
      >
        Отменить
      </r-button>
      <r-button
        type="primary"
        @click="createObject"
      >
        Создать объект
      </r-button>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { exceptionsFields } from './configs'
import { parseValue } from './helpers'

export default {
  data() {
    return {
      reflectionsData: {},
      activeFields: [],
      loading: false,
      selectLoading: false
    }
  },
  computed: {
    hasChanges() {
      return this.activeFields?.some(e => {
        if (Array.isArray(e.value)) {
          return e.value?.length
        } else {
          return e.value !== null
        }
      })
    },
    initialActiveFields() {
      return this.$store.state.book.activeFields?.fields || null
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    activeBookSourceId() {
      return this.activeBook.source_id || null
    },
    reflections() {
      return this.$store.state.book.reflections || null
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  async created() {
    try {
      this.loading = true
      // await this.loadReflections()
      await this.setActiveFields()
    } catch (e) {
      throw new Error(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async loadReflections(ref_source_id) {
      try {
        let reflection = this.initialActiveFields.filter(e => e.reflection)
        if (ref_source_id) {
          reflection = reflection.filter(e => e.source_id === ref_source_id && !this.reflectionsData[e.name])
        }
        this.isLoading = true
        this.selectLoading = true
        await Promise.all(
          reflection.map(async({ reflection }) => {
            const source_name = reflection.name
            const source_id = reflection.source_id

            if (this.reflectionsData[source_name]) return

            const config = { only: ['id', reflection.default_show_attribute].filter(e => e) }
            const { data } = await this.$store.dispatch('GET_REQUEST', {
              url: `objectInfo/${source_id}?array=true&config=${JSON.stringify(config)}`
            })

            this.reflectionsData[source_name] = data
          })
        )
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
        this.selectLoading = false
      }
    },
    getReflectionLabelAttr(field, item) {
      if (field.attrName) {
        return item?.[field.attrName] === true ? '✓' : item?.[field.attrName] || '-'
      }
      if (field.reflection) {
        const attrName = field.default_show_attribute

        return item[attrName] || item?.name || '-'
      }
      return item.name || '-'
    },
    isDisabled(field, fieldObject) {
      const fieldInfo = this.initialActiveFields?.find(
        e => e.source_name === field
      )
      if (exceptionsFields.includes(field)) return true
      return fieldInfo?.read_only || fieldInfo?.system_field || fieldObject?.read_only
    },
    setActiveFields() {
      this.activeFields = this.initialActiveFields
        ?.filter(k => {
          return !k.read_only && !k.system_field
        })
        ?.map((e, i) => {
          return {
            id: i,
            name: e.alias || e.title,
            title: e.origin_title || e.title,
            origin_title: e.origin_title,
            value: null,
            type: this.getFieldType(e),
            reflection: e?.reflection?.source_id || null,
            default_show_attribute: e?.reflection?.default_show_attribute
          }
        })
    },
    getFieldType(field) {
      switch (field?.reflection?.type) {
        case 'has_many_through':
        case 'has_many':
          return 'multi-select'
        case 'belongs_to':
        case 'has_one':
          return 'select'
        default:
          if (field.type === 'boolean') return 'boolean'
          if (field.type === 'datetime' || field.datatype === 'datetime') return 'datetime'
          if (field.type === 'date' || field.datatype === 'date') return 'date'
          return 'input'
      }
    },
    getReflectionOptions(field) {
      if (!field.reflection) return []
      return this.reflectionsData?.[field.title]
    },
    getReflectionOptionsRead(field) {
      return this.reflectionsData?.[field.title] || []
    },
    getDropDownData(event, field) {
      if (event) {
        this.loadReflections(field.reflection)
      }
    },
    cancel() {
      const title = this.$t('before:title')
      const message = this.$t('before-cancel:text')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')

      this.$confirm(message, title, {
        customClass: 'r-message-box',
        closeOnPressEscape: true,
        closeOnClickModal: false,
        type: 'warning',
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.$store.commit('BOOK_TOGGLE_MODAL', false)
        })
        .catch(() => {})
    },
    async createObject() {
      this.loading = true
      const data = this.getData()

      try {
        await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${this.activeBookSourceId}`,
          data
        })

        this.$notify(
          notifyFactory(
            'success',
            'Создание нового объекта',
            'Объект успешно создан'
          )
        )
        this.$store.commit('UPDATE_ACTIVE_TABLE', true)
        this.$store.commit('BOOK_TOGGLE_MODAL', false)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    getData() {
      const modifiedFields = {}
      this.activeFields.forEach(f => {
        const { type } = f
        const reflectionsArray = Object.values(this.reflections)
        const reflection = reflectionsArray?.find(e => e.alias === f.origin_title)

        if (reflection) {
          const { foreign_key, through } = reflection

          if (!foreign_key) return
          if (through) {
            if (!modifiedFields[through]) {
              modifiedFields[through] = f.value.map(item => {
                return { [foreign_key]: parseValue(item, type) }
              })
            } else {
              modifiedFields[through].push(
                ...f.value.map(item => {
                  return { [foreign_key]: parseValue(item, type) }
                }))
            }
          } else {
            modifiedFields[foreign_key] = parseValue(f.value, type)
          }
        } else {
          modifiedFields[f.origin_title] = parseValue(f.value, type)
        }
      })
      return modifiedFields
    }
  }
}
</script>

<style lang="scss" scoped>
.book-create {
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 12px;

  &-wrapper {
    height: calc(100vh - 280px);
    overflow: auto;
    padding-bottom: 20px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    > * {
      &:first-child {
        margin-right: 16px;
        width: 160px;
      }
      &:last-child {
        width: calc(100% - 160px - 16px);
      }
    }
  }

  &__control {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: end;
    margin-top: 12px;
  }
}
</style>
