<template>
  <div
    v-loading="isLoading"
    class="book-multiedit"
  >
    <div class="book-multiedit-wrapper">
      <div
        v-for="field in activeFields"
        :key="field.id"
        class="book-multiedit__row"
      >
        <r-text color-type="subhead">
          {{ field.name }}
        </r-text>
        <el-date-picker
          v-if="field.datatype === 'datetime' || field.type === 'datetime'"
          v-model="field.value"
          class="r-date-picker"
          type="date"
          name="date"
          format="dd.MM.yyyy HH:mm"
          :clearable="false"
          :disabled="isDisabled(field.title, field)"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="changeHandler(field.title)"
        />
        <el-date-picker
          v-if="field.datatype === 'date' || field.type === 'date'"
          v-model="field.value"
          class="r-date-picker"
          type="date"
          name="date"
          format="dd.MM.yyyy"
          :clearable="false"
          :disabled="isDisabled(field.title, field)"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="changeHandler(field.title)"
        />
        <el-input
          v-if="field.type === 'input'"
          v-model="field.value"
          class="r-input"
          placeholder="Введите текст"
          :disabled="isDisabled(field.title, field)"
          @change="changeHandler(field.title)"
        />
        <el-switch
          v-if="field.type === 'boolean'"
          v-model="field.value"
          class="r-switch"
          :disabled="isDisabled(field.title, field)"
          @change="changeHandler(field.title)"
        />
        <el-input
          v-else-if="(field.type === 'select' || field.type === 'multi-select') && isDisabled(field.title, field)"
          :value="getDisabledSelectValueRead(field)"
          class="r-input"
          type="textarea"
          :disabled="isDisabled(field.title, field)"
          @change="changeHandler(field.title)"
        />
        <el-select
          v-else-if="field.type === 'select' || field.type === 'multi-select'"
          v-model="field.value"
          class="r-select"
          placeholder="Выбрать"
          :multiple="field.type === 'multi-select'"
          :disabled="isDisabled(field.title, field)"
          :loading="selectLoading"
          filterable
          clearable
          @visible-change="getDropDownData($event, field)"
          @change="changeHandler(field.title)"
        >
          <el-option
            v-for="item in getReflectionOptionsRead(field)"
            :key="item.id"
            :label="getReflectionLabelAttr(field, item)"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <div class="book-multiedit__control">
      <r-button
        simple
        @click="cancel"
      >
        Отменить
      </r-button>
      <r-button
        type="primary"
        :disabled="!hasChanges"
        @click="beforeSave"
      >
        Изменить выбранные объекты
      </r-button>
    </div>
  </div>
</template>

<script>
// import isEqual from 'lodash.isequal'
import { notifyFactory } from '@/utils'
import { exceptionsFields } from './configs'
import { dateChecker, parseValue, getObjectConfig } from './helpers'

export default {
  data() {
    return {
      reflectionsData: {},
      activeFields: [],
      isLoading: false,
      changedFields: {},
      initialBookData: [],
      selectLoading: false
    }
  },
  computed: {
    showModal() {
      return this.$store.state.book.showModal
    },
    hasChanges() {
      return this.activeFields?.some(e => {
        if (Array.isArray(e.value)) {
          return e.value?.length
        } else {
          return e.value !== null
        }
      })
    },
    initialActiveFields() {
      return this.$store.state.book.activeFields?.fields || null
    },
    reflectionsFields() {
      return this.initialActiveFields?.filter(f => f.reflection)?.map(f => {
        return {
          ...f,
          ...f.reflection
        }
      })
    },
    activeBook() {
      return this.$store.state.book.activeBook || {}
    },
    activeBookSourceId() {
      return this.activeBook.source_id || null
    },
    reflections() {
      return this.$store.state.book.reflections || null
    },
    selectedObjects() {
      return this.$store.state.tablePrime.selectedObjects.book_table || null
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('BOOK_SET_FIELD', {
        field: 'modalHasChanges',
        value: val
      })
    }
  },
  async created() {
    try {
      this.loading = true
      // await this.loadReflections()
      await this.loadObject()
    } catch (e) {
      throw new Error(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async loadReflections(ref_source_id) {
      try {
        let reflection = this.initialActiveFields.filter(e => e.reflection)
        if (ref_source_id) {
          reflection = reflection.filter(e => e.source_id === ref_source_id && !this.reflectionsData[e.name])
        }
        this.isLoading = true
        this.selectLoading = true
        await Promise.all(
          reflection.map(async({ reflection }) => {
            const source_name = reflection.name
            const source_id = reflection.source_id

            if (this.reflectionsData[source_name]) return

            const config = { only: ['id', reflection.default_show_attribute].filter(e => e) }
            const { data } = await this.$store.dispatch('GET_REQUEST', {
              url: `objectInfo/${source_id}?array=true&config=${JSON.stringify(config)}`
            })

            this.reflectionsData[source_name] = data
          })
        )
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
        this.selectLoading = false
      }
    },
    async loadObject() {
      try {
        const ids = this.selectedObjects?.map(e => e.id)
        const config = getObjectConfig(ids, this.initialActiveFields)
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.activeBookSourceId}?config=${JSON.stringify(config)}&array=true`
        })

        this.initialBookData = data || []

        this.setActiveFields()
      } catch (e) {
        throw new Error(e)
      }
    },
    getDisabledSelectValue(field) {
      const options = this.getReflectionOptions(field.reflection) || []

      if (field.deReflect) {
        return field.value?.map(e => {
          const value = e?.[field?.attrName || 'name']

          return dateChecker(value) || '- '
        })?.join(', ')
      }

      if (!Array.isArray(field.value)) return field.value || '-'

      return field.value?.map(e => {
        const value = options?.[e]?.[field?.attrName || 'name']

        return dateChecker(value) || '- '
      })?.join(', ')
    },
    getDisabledSelectValueRead(field) {
      const options = this.getReflectionOptionsRead(field) || []
      if (field.deReflect) {
        return field.value?.map(e => {
          const value = e?.[field?.default_show_attribute || 'name']

          return dateChecker(value) || '- '
        })?.join(', ')
      }
      if (!Array.isArray(field.value)) {
        if (options && options.length > 0) {
          return options[0][field.default_show_attribute || 'name'] || field.value
        } else {
          return field.value
        }
      }

      return field.value?.map(e => {
        const value = options?.[e]?.[field?.attrName || 'name']

        return dateChecker(value) || '- '
      })?.join(', ')
    },
    getReflectionLabelAttr(field, item) {
      if (field.attrName) {
        return item?.[field.attrName] === true ? '✓' : item?.[field.attrName] || '-'
      }
      if (field.reflection) {
        const attrName = field.default_show_attribute

        return item[attrName] || item?.name || '-'
      }
      return item.name || '-'
    },
    isDisabled(field, fieldObject) {
      const fieldInfo = this.initialActiveFields?.find(
        e => e.source_name === field
      )
      if (exceptionsFields.includes(field)) return true
      return fieldInfo?.read_only || fieldInfo?.system_field || fieldObject?.read_only
    },
    changeHandler(title) {
      this.changedFields[title] = true
    },
    setActiveFields() {
      this.activeFields = this.initialActiveFields
        ?.filter(k => {
          return !k.system_field
        })
        ?.map((e, i) => {
          return {
            id: i,
            name: e.alias || e.title,
            title: e.origin_title || e.title,
            origin_title: e.origin_title,
            value: null,
            type: this.getFieldType(e),
            read_only: !!e?.read_only || e.type === 'has_many',
            system_field: !!e?.system_field,
            attrName: e?.attrName || null,
            reflection: e?.reflection?.source_id || null,
            default_show_attribute: e?.reflection?.default_show_attribute
          }
        }).sort((a, b) => {
          if (b.read_only) return -1
          if (!b.read_only) return 1
          return 0
        })
    },
    getFieldType(field) {
      switch (field?.reflection?.type) {
        case 'has_many_through':
        case 'has_many':
          return 'multi-select'
        case 'belongs_to':
        case 'has_one':
          return 'select'
        default:
          if (field.type === 'boolean') return 'boolean'
          if (field.type === 'datetime' || field.datatype === 'datetime') return 'datetime'
          if (field.type === 'date' || field.datatype === 'date') return 'date'
          return 'input'
      }
    },
    getReflectionOptions(id) {
      if (!id) return []
      return this.reflectionsData?.[id]
    },
    getReflectionOptionsRead(field) {
      let objectValue = this.activeObject?.[field.title]
      if (objectValue) {
        objectValue = Array.isArray(objectValue) ? objectValue : [objectValue]
      }
      return this.reflectionsData?.[field.title] || objectValue || []
    },
    getDropDownData(event, field) {
      if (event) {
        this.loadReflections(field.reflection)
      }
    },
    cancel() {
      const title = this.$t('before:title')
      const message = this.$t('before-cancel:text')
      const confirmButtonText = this.$t('button-confirm')
      const cancelButtonText = this.$t('button-cancel')

      this.$confirm(message, title, {
        customClass: 'r-message-box',
        closeOnPressEscape: true,
        closeOnClickModal: false,
        type: 'warning',
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.$store.commit('BOOK_TOGGLE_MODAL', false)
        })
        .catch(() => {})
    },
    beforeSave() {
      if (!this.hasChanges) return

      const warningText = 'Сохранить все внесённые изменения?'
      const warningTitle = 'Сохранить изменения'
      const confirmButtonText = 'Сохранить'
      const cancelButtonText = 'Нет'
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.saveChanges()
        })
        .catch(() => {})
    },
    async saveChanges() {
      this.isLoading = true
      const data = this.getChangedValues()

      try {
        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.activeBookSourceId}`,
          data
        })

        this.$notify(
          notifyFactory('success', 'Сохранение', 'Данные успешно сохранены')
        )
        this.$store.commit('UPDATE_ACTIVE_TABLE', true)
        this.$store.commit('BOOK_TOGGLE_MODAL', false)
        this.$store.commit('BOOK_SET_FIELD', {
          field: 'modalHasChanges',
          value: false
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    getChangedValues() {
      const newData = Object.assign([], this.initialBookData)
      const changedValues = []
      const changedRows = {}

      newData.forEach(e => {
        const item = {}

        this.activeFields.forEach(({ title, read_only, value, type }) => {
          if (read_only) return
          if (['created_user', 'updated_user']?.includes(title)) return

          if (Array.isArray(value)) {
            item[title] = value
          } else if (!Array.isArray(value)) {
            item[title] = parseValue(value, type)
          }
        })
        console.log(e.id, item)

        changedRows[e.id] = item
      })

      for (const key in changedRows) {
        // const initialRowData = this.initialBookData.find(e => e.id === key)
        const changedRowData = changedRows[key]
        const modifiedFields = {}
        for (const changedKey in this.changedFields) {
          const f = this.activeFields.find(e => e.origin_title === changedKey)
          const changed = changedRowData[f.origin_title] !== null
          console.log(f.origin_title, changed)
          const { type } = f
          const reflectionsArray = Object.values(this.reflections)
          const reflection = reflectionsArray?.find(e => e.alias === f.origin_title)

          if (reflection) {
            const { foreign_key, through } = reflection

            if (!foreign_key) return
            if (through) {
              if (!modifiedFields[through]) {
                modifiedFields[through] = f.value.map(item => {
                  return { [foreign_key]: parseValue(item, type) }
                })
              } else {
                modifiedFields[through].push(
                  ...f.value.map(item => {
                    return { [foreign_key]: parseValue(item, type) }
                  }))
              }
            } else {
              modifiedFields[foreign_key] = parseValue(f.value, type)
            }
          } else {
            modifiedFields[f.origin_title] = parseValue(f.value, type)
          }
        }
        modifiedFields.id = key
        changedValues.push({ ...modifiedFields, _action: 'updated' })
      }

      return changedValues
    }
  }
}
</script>

<style lang="scss" scoped>
.book-multiedit {
  position: relative;
  max-height: calc(100vh - 200px);
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 12px;

  &-wrapper {
    height: calc(100vh - 280px);
    overflow: auto;
    padding-bottom: 20px;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    > * {
      &:first-child {
        margin-right: 16px;
        width: 160px;
      }
      &:last-child {
        width: calc(100% - 160px - 16px);
      }
    }
  }

  &__control {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: end;
    margin-top: 12px;
  }

  .el-textarea {
    &.is-disabled {
      opacity: 0.5;
    }
  }
}
</style>
