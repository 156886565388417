<template>
  <div class="book-modal">
    <r-title class="book-modal__title">
      {{ $t(modalName) }}
    </r-title>
    <component
      :is="modalName"
      v-if="modalName"
    />
    <r-text v-else>
      Произошла ошибка. Попробуйте еще раз или обратитесь к администратору.
    </r-text>
  </div>
</template>

<script>
import bookCreate from './book-create'
import bookInfo from './book-info'
import bookEdit from './book-edit'

export default {
  components: {
    bookCreate,
    bookInfo,
    bookEdit
  },
  computed: {
    modalName() {
      return this.$store.state.book.modalName || null
    }
  }
}
</script>

<style lang="scss">
.book-modal {
  position: relative;
  width: calc(800px - 32px);

  &__title {
    margin-bottom: 8px;
  }
}
</style>

<i18n>
{
  "ru": {
    "book-create": "Создать новый объект",
    "book-info": "Редактировать объект",
    "book-edit": "Редактировать группу объектов"
  }
}
</i18n>
